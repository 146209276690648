<template>
    <v-card>
        <v-snackbar v-model="snackbar" color="success" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('create_academic')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-text-field
                            v-model="editedData.universityName"
                            :label="$t('table.university_name')"
                            required outlined dense
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.academicYear"
                            :error-messages="academicYearErrors"
                             :label="$t('academic_year')"
                            required outlined dense
                            @input="$v.editedData.academicYear.$touch()"
                            @blur="$v.editedData.academicYear.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.academicPrograme"
                            :error-messages="academicProgrameErrors"
                             :label="$t('academic_program')"
                            required outlined dense
                            @input="$v.editedData.academicPrograme.$touch()"
                            @blur="$v.editedData.academicPrograme.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.faculty"
                            :label="$t('faculty')"
                            required outlined dense
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.fee"
                            type="number"
                            :label="$t('fee')"
                            required outlined dense
                    ></v-text-field>

                    <v-text-field
                            v-model="editedData.numberOfSeat"
                            type="number"
                            :label="$t('number_of_seat')"
                            required outlined dense
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.occupiedSeat"
                            type="number"
                             :label="$t('occupied_seat')"
                            required outlined dense
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.tentativeBudget"
                            type="number"
                            :label="$t('tentative_budget')"
                            required outlined dense
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157,25,25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success"],
        computed: {
            academicYearErrors() {
                const errors = [];
                if (!this.$v.editedData.academicYear.$dirty) return errors;
                !this.$v.editedData.academicYear.required &&
                errors.push("Sector Type is required");
                return errors;
            },
            academicProgrameErrors() {
                const errors = [];
                if (!this.$v.editedData.academicPrograme.$dirty) return errors;
                !this.$v.editedData.academicPrograme.required &&
                errors.push("Sector Code is required.");
                return errors;
            }
        },
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated) {
                        this.updated = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 3000
                },
                snackbar: false,
                province: [],
                show: false,
                updated: false,
                editedData: {
                    academicYear: "",
                    academicPrograme: "",
                    faculty: "",
                    fee: "",
                    numberOfSea: "",
                    occupiedSeat: "",
                    tentativeBudget: ""
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                academicYear: {required},
                academicPrograme: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                this.updated = false;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        universityName: this.editedData.universityName,
                        academicYear: this.editedData.academicYear,
                        academicPrograme: this.editedData.academicPrograme,
                        faculty: this.editedData.faculty,
                        fee: parseInt(this.editedData.fee),
                        numberOfSeat: parseInt(this.editedData.numberOfSeat),
                        occupiedSeat: parseInt(this.editedData.occupiedSeat),
                        tentativeBudget: parseInt(this.editedData.tentativeBudget)
                    };
                    axios.post("Academic/InsertIntoAcademicSettingAsync", param).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Academic added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        } else {
                            this.snackbar = true;
                            this.callbackResponse.message = response.data.errors[0]
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.academicYear = "";
                this.editedData.academicPrograme = "";
            }
        }
    };
</script>

<style scoped>
</style>